export const newsBoxes = [
  {
    box1: [3, `this.state.filtered1`, "Najnovije vesti", "boxWrapper sideBox"],
  },
];

export const newsShedule = [
  { home: [147, 159, 160] }, //actual news ids
  { home_topNews: [154, 114] }, //top news ids
];
export const newsShedule2 = [
  [[1, 4], "home__rightBox", "boxWrapper box1"],
  [[4, 7], "home__leftBox", "boxWrapper box1"],
  [[8, 10], "home__box__4-layout", "boxWrapper mainBox bigBox"],
  [[10, 14], "home__box__4-layout", "boxWrapper box4"],
];
export const options = [
  //0
  {
    title: "Početna",
    route: "",
    icon: "/img/icons/home.png",
    social: {
      title: "Agroweb.rs: Sve o poljoprivredi na jednom mestu",
      lead: "Agroweb.rs - Poljoprivredni portal: Vesti, saveti i informacije iz poljoprivrede na jednom mestu",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      time2: "2021-06-01T13:30:03.969Z",
    },
  },
  //1
  {
    title: "Vesti",
    route: "/vesti/",
    icon: "/img/icons/other.png",
    social: {
      title: "Vesti - Agroweb.rs",
      lead: "Vesti - Agroweb.rs",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
    item_list: [
      {
        title: "Voćarstvo",
        route: "/vesti/vocarstvo/",
        icon: "/img/icons/vocke.png",
        social: {
          title: "Voćarstvo - Agroweb.rs",
          lead: "Voćarstvo - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Vinogradarstvo",
        route: "/vesti/vinogradarstvo/",
        icon: "/img/icons/vinogradarstvo.png",
        social: {
          title: "Vinogradarstvo - Agroweb.rs",
          lead: "Vinogradarstvo - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Stočarstvo",
        route: "/vesti/stocarstvo/",
        icon: "/img/icons/stocarstvo.png",
        social: {
          title: "Stočarstvo - Agroweb.rs",
          lead: "Stočarstvo - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Pčelarstvo",
        route: "/vesti/pcelarstvo/",
        icon: "/img/icons/honey.png",
        social: {
          title: "Pčelarstvo - Agroweb.rs",
          lead: "Pčelarstvo - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Ratarstvo",
        route: "/vesti/ratarstvo/",
        icon: "/img/icons/ratarstvo.png",
        social: {
          title: "Ratarstvo - Agroweb.rs",
          lead: "Ratarstvo - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Povrtarstvo",
        route: "/vesti/povrtarstvo/",
        icon: "/img/icons/povrtarstvo.png",
        social: {
          title: "Povrtarstvo - Agroweb.rs",
          lead: "Povrtarstvo - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Mehanizacija",
        route: "/vesti/mehanizacija/",
        icon: "/img/icons/mehanizacija.png",
        social: {
          title: "Mehanizacija - Agroweb.rs",
          lead: "Mehanizacija - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Prehranbena industrija",
        route: "/vesti/prehranbena-industrija/",
        icon: "/img/icons/industrija.png",
        social: {
          title: "Prehranbena industrija - Agroweb.rs",
          lead: "Prehranbena industrija - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Zaštita bilja",
        route: "/vesti/zastita-bilja/",
        icon: "/img/icons/zastita.png",
        social: {
          title: "Zaštita bilja - Agroweb.rs",
          lead: "Zaštita bilja - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Ekološka poljoprivreda",
        route: "/vesti/ekoloska-poljoprivreda/",
        icon: "/img/icons/zastita.png",
        social: {
          title: "Ekološka poljoprivreda - Agroweb.rs",
          lead: "Ekološka poljoprivreda - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Poljoprivredno tržište",
        route: "/vesti/poljoprivredno-trziste/",
        icon: "/img/icons/zastita.png",
        social: {
          title: "Poljoprivredno tržište - Agroweb.rs",
          lead: "Poljoprivredno tržište - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      {
        title: "Bezbednost hrane",
        route: "/vesti/bezbednost-hrane/",
        icon: "/img/icons/zastita.png",
        social: {
          title: "Bezbednost hrane - Agroweb.rs",
          lead: "Bezbednost hrane - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      // {
      //   title: "Ukrasno bilje",
      //   route: "/vesti/ukrasno-bilje",
      //   icon: "/img/icons/ukrasno.png",
      //   social: {
      //     title: "Ukrasno bilje",
      //     lead: "Najnovije vesti - Ukrasno bilje",
      //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      //   },
      // },
    ],
    social: {
      title: "Vesti - Agroweb.rs",
      lead: "Najnovije vesti iz poljoprivrede",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },
  //2
  {
    title: "Najnovije",
    route: "/najnovije-vesti/",
    icon: "/img/icons/other.png",
    social: {
      title: "Najnovije vesti - Agroweb.rs",
      lead: "Najnovije vesti iz poljoprivrede - Agroweb.rs",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },
  //3
  {
    title: "Subvencije",
    route: "/vesti/subvencije/",
    icon: "/img/icons/other.png",
    social: {
      title: "Subvencije - Agroweb.rs",
      lead: "Subvencije u poljoprivredi - Agroweb.rs",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },
  //4
  {
    title: "Produktna berza",
    route: "/vesti/produktna-berza/",
    icon: "/img/icons/other.png",
    social: {
      title: "Produktna berza - Agroweb.rs",
      lead: "Produktna berza - Agroweb.rs",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },
  // 5
  {
    title: "Manifestacije",
    route: "/vesti/manifestacije/",
    icon: "/img/icons/manifestacije.png",
    social: {
      title: "Manifestacije - Agroweb.rs",
      lead: "Manifestacije - Agroweb.rs",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },
  // {
  //   title: "Život na selu",
  //   route: "/zivot-na-selu",
  //   icon: "/img/icons/other.png",
  //   social: {
  //     title: "Život na selu - Agroweb.rs",
  //     lead: "Život na selu - Agroweb.rs",
  //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
  //   },
  // },
  // {
  //   title: "Organska proizvodnja",
  //   route: "/vesti/organska-proizvodnja",
  //   icon: "/img/icons/organic.png",
  //   social: {
  //     title: "Organska proizvodnja",
  //     lead: "Najnovije vesti - Organska proizvodnja",
  //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
  //   },
  // },

  //6
  {
    title: "Život na selu",
    route: "/zivot-na-selu/",
    pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    item_list: [
      {
        title: "Običaji i tradicija",
        route: "/zivot-na-selu/obicaji-i-tradicija/",
        icon: "/img/icons/other.png",
        social: {
          title: "Običaji i tradicija",
          lead: "Najnovije vesti - Običaji i tradicija",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      // {
      //   title: "Seoske priče",
      //   route: "/zivot-na-selu/seoske-price/",
      //   icon: "/img/icons/other.png",
      // },
      // {
      //   title: "Zdrav život",
      //   route: "/zivot-na-selu/zdrav-zivot/",
      //   icon: "/img/icons/other.png",
      // },
      // {
      //   title: "Tradicionalni recepti",
      //   route: "/zivot-na-selu/tradicionalni-recepti/",
      //   icon: "/img/icons/other.png",
      //   social: {
      //     title: "Tradicionalni recepti",
      //     lead: "Najnovije vesti - Tradicionalni recepti",
      //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      //   },
      // },
    ],
    social: {
      title: "Život na selu",
      lead: "Najnovije vesti - Život na selu",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },

  //7
  {
    title: "Ostalo",
    route: "/vesti/ostalo/",
    icon: "/img/icons/other.png",
    item_list: [
      // {
      //   title: "Seoski turizam",
      //   route: "/seoski-turizam",
      //   icon: "/img/icons/other.png",
      // },
      // {
      //   title: "Kalkulatori",
      //   route: "/kalkulatori",
      //   icon: "/img/icons/other.png",
      // },
      {
        title: "Organska proizvodnja",
        route: "/vesti/organska-proizvodnja/",
        icon: "/img/icons/organic.png",
        social: {
          title: "Organska proizvodnja - Agroweb.rs",
          lead: "Organska proizvodnja - Agroweb.rs",
          pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
        },
      },
      // {
      //   title: "Manifestacije",
      //   route: "/vesti/manifestacije",
      //   icon: "/img/icons/manifestacije.png",
      //   social: {
      //     title: "Manifestacije - Agroweb.rs",
      //     lead: "Manifestacije - Agroweb.rs",
      //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      //   },
      // },
      // {
      //   title: "Zdrava ishrana",
      //   route: "/zivot-na-selu/zdrava-ishrana",
      //   icon: "/img/icons/other.png",
      //   social: {
      //     title: "Zdrava ishrana - Agroweb.rs",
      //     lead: "Zdrava ishrana - Agroweb.rs",
      //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      //   },
      // },
      // {
      //   title: "Manifestacije",
      //   route: "/vesti/manifestacije",
      //   icon: "/img/icons/manifestacije.png",
      //   social: {
      //     title: "Manifestacije",
      //     lead: "Manifestacije",
      //     pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
      //   },
      // },
      {
        title: "Ostalo",
        route: "/vesti/ostalo/",
        icon: "/img/icons/other.png",
      },
    ],
    social: {
      title: "Ostalo - Agroweb.rs",
      lead: "Ostale vesti - Agroweb.rs",
      pics: "https://res.cloudinary.com/dvi29tcsc/image/upload/v1678366923/ads/agroweb_green_l2ql6w.jpg",
    },
  },
];

export const footer = [
  ["O nama", "/o-nama/"],
  ["Marketing", "/marketing/"],
  ["Pravila korišćenja", "/pravila-koriscenja/"],
  ["Politika privatnosti", "/politika-privatnosti/"],
];

export const infoText = [
  ["O nama", "Agrweb.rs je poljoprivredni portal koji pruža sveobuhvatne informacije o poljoprivrednoj proizvodnji u Srbiji. Na našem portalu možete pronaći najnovije vesti, savete i tehnologije iz oblasti poljoprivrede, kao i informacije o proizvodima i uslugama koje nude domaći poljoprivredni proizvođači.<br /> Cilj nam je da pomognemo poljoprivrednicima da unaprede svoju proizvodnju i da budu uspešni u svojim poslovima. Pratite nas i informišite se o tome kako da unapredite svoju poljoprivrednu proizvodnju. <br /> Pored toga, na portalu nudimo i mogućnost oglašavanja za sve one koji žele da promovišu svoje proizvode i usluge."],
  ["Marketing", 'Pozivamo Vas da iskoristite priliku za jedinstvenu promociju na Agroweb.rs – portalu za poljoprivredu i ruralni razvoj!<br/>Želite da unapredite vidljivost Vašeg brenda, proizvoda ili usluga u pravom trenutku i pred pravom publikom? Nudimo Vam kreativna rešenja i različite mogućnosti oglašavanja koje garantuju uspeh!<br/><br/><h2>Šta nudimo?</h2><ul><li><b>Baneri sa direktnim linkom ka Vašem sajtu</b> – vizuelno upečatljivi i strateški pozicionirani;</li><li><b>Promotivni članci</b> – prenesite Vašu poruku kroz kvalitetan sadržaj pisan specijalno za Vas; </li><li><b>Prezentacija proizvoda i usluga</b> – promovišite svoje inovacije i rešenja ciljanoj publici; </li><li><b>Najave događaja</b> – povećajte prisustvo i angažovanje na Vašim događajima kroz našu mrežu; </li></ul><br/>Agroweb.rs je mesto gde se susreću stručnjaci, preduzetnici i entuzijasti iz sveta poljoprivrede. Vaš oglas će biti plasiran direktno pred ciljnu publiku koja traži najnovije informacije, savete i proizvode iz oblasti agrara.<br/>Zajedno možemo ostvariti odlične rezultate!<br/><br/>Za više informacija pišite nam na: <a href=mailto:marketing@agroweb.rs className="topBar__link" rel="noopener noreferrer">marketing@agroweb.rs</a>'],
  ["Pravila korišćenja", "Korišćenjem sadržaja sa sajta Agroweb.rs, korisnik prihvata sve rizike koje korišćenje može prouzrokovati, te koristi sadržaj isključivo za ličnu upotrebu i na sopstvenu odgovornost.<br/>Agroweb.rs se u potpunosti ograđuje od bilo kakve odgovornosti koja može nastati iz ili u vezi sa korišćenjem ovih web stranica, kao i za bilo koje postupke korisnika upotrebom ili zloupotrebom sadržaja. Takođe, ograđujemo se od odgovornosti za bilo kakvu štetu koja može nastati korisniku ili trećim licima u vezi sa korišćenjem ili zloupotrebom sadržaja na sajtu.<br/>Agroweb.rs zadržava pravo da menja sadržaj ovih web stranica bez prethodnog obaveštenja, te ne preuzima odgovornost za moguće posledice koje bi mogle proizaći iz takvih promena.<br/>Stavovi i mišljenja autora kolumni su lični i ne odražavaju nužno stavove redakcije portala.<br/><br/><h2>Uslovi preuzimanja</h2>Sva zainteresovana pravna i fizička lica mogu slobodno koristiti tekstualne sadržaje sa sajta Agroweb.rs pod sledećim uslovima:<ul><li>Navesti izvor Agroweb.rs i postaviti aktivni link na originalni tekst;</li><li>Dozvoljeno je preuzimanje celog ili delimičnog sadržaja uz navođenje autora;</li><li>Preuzimanje i distribucija sadržaja dozvoljeni su u nekomercijalne svrhe i za potrebe informisanja, uz poštovanje autorskih prava.</li></ul>"],
  ["Politika privatnosti", ""],
];

export const asideBoxes = [
  [0, 2, "Najnovije vesti - ", "boxWrapper mainBox new", "home__rightBox", true, "home__box__13"],
  [1, 3, "Preporučujemo", "boxWrapper box2", "home__rightBox", false, "home__box__11"],
  [2, 3, "Najnovije vesti", "boxWrapper sideBox"],
  [3, 3, "Najčitanije vesti", "boxWrapper sideBox"],
];

export const asideBoxes2 = [[4, 2, "Izdvojeno", "boxWrapper box4 inText"]];

export const asideBoxesNews = [
  [4, 7, "", "home__box__11", "home__rightBox", false, "boxWrapper box2"],
  [0, 3, "Voćarstvo", "home__box__3", "home__box__3-wrapper", true, "boxWrapper mainBox box3 dark"],
  [0, 3, "Subvencije", "home__box__2", "home__box__2-wrapper", true, "boxWrapper mainBox box3 white"],
  [0, 3, "Organska proizvodnja", "home__box__71", "home__rightBox", true, "boxWrapper box2", "", "home__box__71-wrapper"],
  [0, 3, "Manifestacije", "home__box__3", "home__box__3-wrapper", true, "boxWrapper mainBox box3 dark"],
  [0, 3, "Ostalo", "home__box__71", "home__rightBox", true, "boxWrapper box2", "home__box__71-wrapper"],
];

export const asideBoxesNews2 = [
  // [4, 7, "", "home__box__11", "home__rightBox", false, "boxWrapper box2"],
  // [0, 3, "Tradicionalni recepti", "home__box__3", "home__box__3-wrapper", true, "boxWrapper mainBox box3 dark"],
  // [0, 3, "Seoske priče", "home__box__2", "home__box__2-wrapper", true, "boxWrapper mainBox box3 white"],
  // [0, 3, "Zdrav život", "home__box__71", "home__rightBox", true, "boxWrapper box2", "", "home__box__71-wrapper"],
  [0, 3, "Običaji i tradicija", "home__box__3", "home__box__3-wrapper", true, "boxWrapper mainBox box3 dark"],
];
